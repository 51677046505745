.line-height-12 {
  line-height: 12px;
}

.line-height-18 {
  line-height: 18px;
}

.line-height-24 {
  line-height: 24px;
}

.line-height-30 {
  line-height: 30px;
}

.font-size-10 {
  font-size: 10px;
}

.font-size-12 {
  font-size: 12px;
}

.font-size-14 {
  font-size: 14px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-24 {
  font-size: 24px;
}

.v-padding-2 {
  padding-top: 2px;
  padding-bottom: 2px;
}

.v-padding-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}

.v-padding-5 {
  padding-top: 5px;
  padding-bottom: 5px;
}

.v-padding-6 {
  padding-top: 6px;
  padding-bottom: 6px;
}

.v-padding-8 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.v-padding-9 {
  padding-top: 9px;
  padding-bottom: 9px;
}

.v-padding-11 {
  padding-top: 11px;
  padding-bottom: 11px;
}

.v-padding-12 {
  padding-top: 12px;
  padding-bottom: 12px;
}

.v-padding-14 {
  padding-top: 14px;
  padding-bottom: 14px;
}

.v-padding-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

.v-padding-17 {
  padding-top: 17px;
  padding-bottom: 17px;
}

.v-padding-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}

.v-padding-23 {
  padding-top: 23px;
  padding-bottom: 23px;
}

.v-padding-24 {
  padding-top: 24px;
  padding-bottom: 24px;
}