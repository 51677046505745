.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: 2px;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

:global(.uui-dropdownMenu-body) {
  --uui-dropdown_menu-bg: var(--uui-surface-main);
  --uui-dropdown_menu-shadow: var(--uui-shadow-level-2);
  --uui-dropdown_menu-splitter: var(--uui-divider-light);
  --uui-dropdown_menu-item-hover: var(--uui-surface-higher);
  --uui-dropdown_menu-item-active: var(--uui-surface-higher);
  --uui-dropdown_menu-item-text: var(--uui-text-primary);
  --uui-dropdown-selected_mark: var(--uui-info-50);
  --uui-dropdown_menu-body-font: var(--uui-font);
  --uui-dropdown_menu-body-font-weight: 400;
  --uui-dropdown_menu-item-font: var(--uui-font);
  --uui-dropdown_menu-item-font-weight: 400;
  color: var(--uui-text-primary);
  fill: var(--uui-icon);
  font-family: var(--uui-dropdown_menu-body-font);
  font-weight: var(--uui-dropdown_menu-body-font-weight);
  font-size: 14px;
  padding: 6px 0;
  background-color: var(--uui-dropdown_menu-bg);
  box-shadow: var(--uui-shadow-level-1);
  border-radius: var(--uui-dropdown_container-border-radius);
}

.submenu-root-item .icon-after {
  transform: rotate(-90deg);
}

.icon-check {
  color: transparent;
  fill: transparent;
}

.splitter-root {
  display: flex;
  width: 100%;
}
.splitter-root .splitter {
  width: 100%;
  border: 0;
  border-top: 1px solid var(--uui-dropdown_menu-splitter);
  height: 0;
}

.header-root {
  color: var(--uui-text-secondary);
  fill: var(--uui-text-secondary);
  padding: 9px 12px;
  line-height: 18px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  font-family: var(--uui-dropdown_menu-item-font);
  font-weight: var(--uui-dropdown_menu-item-font-weight);
  cursor: default;
  height: auto;
  font-size: 12px;
  padding: 3px 12px;
}
.header-root:focus {
  outline: none;
}

.item-root {
  padding: 9px 12px;
  line-height: 18px;
  font-size: 14px;
  box-sizing: border-box;
  width: 100%;
  height: 36px;
  font-family: var(--uui-dropdown_menu-item-font);
  font-weight: var(--uui-dropdown_menu-item-font-weight);
  color: var(--uui-text-primary);
  fill: var(--uui-icon);
}
.item-root:global(.uui-active) {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root:global(.uui-active):hover, .item-root:global(.uui-active):focus {
  color: var(--uui-primary-50) !important;
  fill: var(--uui-primary-50) !important;
}
.item-root:global(.uui-active) .icon-check {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root:focus {
  outline: none;
}
.item-root .icon {
  justify-content: flex-start;
}
.item-root .icon:global(.uui-active) {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root .icon:global(.uui-active):hover, .item-root .icon:global(.uui-active):focus {
  color: var(--uui-primary-50) !important;
  fill: var(--uui-primary-50) !important;
}
.item-root .icon:global(.uui-active) .icon-check {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root .icon svg {
  width: 18px;
  height: 18px;
}
.item-root.link {
  display: flex;
  align-items: center;
}
.item-root.link:global(.uui-active) {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root.link:global(.uui-active):hover, .item-root.link:global(.uui-active):focus {
  color: var(--uui-primary-50) !important;
  fill: var(--uui-primary-50) !important;
}
.item-root.link:global(.uui-active) .icon-check {
  color: var(--uui-primary-50);
  fill: var(--uui-primary-50);
}
.item-root.link:focus {
  outline: none;
}
.item-root:global(.-clickable:not(.uui-disabled)):hover {
  background-color: var(--uui-dropdown_menu-item-hover);
  fill: var(--uui-icon-active);
  cursor: pointer;
}
.item-root:global(.-clickable:not(.uui-disabled)):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: -2px;
}
.item-root:global(.uui-disabled) {
  color: var(--uui-text-disabled);
  fill: var(--uui-text-disabled);
  pointer-events: none;
}
.item-root:global(.uui-disabled):hover, .item-root:global(.uui-disabled):focus {
  background-color: transparent;
  cursor: default;
}
.item-root:global(.uui-opened) {
  background-color: var(--uui-dropdown_menu-item-active);
  fill: var(--uui-text-primary);
}
.item-root > *:not(:last-child) {
  margin-inline: 0 7px;
}

.indent {
  padding-left: 24px;
}

.selected-mark {
  fill: var(--uui-dropdown-selected_mark);
}
.selected-mark > svg {
  width: 18px;
  height: 18px;
}