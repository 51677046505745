.root {
  --uui-avatar_stack-font: var(--uui-font);
  --uui-avatar_stack-font-weight: 600;
}
.root :global(.avatars) {
  padding-right: 12px;
}
.root :global(.avatars) > * {
  border-radius: 50%;
  border: 1px solid white;
}
.root :global(.avatars) :global(.-clickable):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: 50%;
}
.root :global(.avatars) :global(.-clickable):focus-visible, .root :global(.avatars) :global(.-clickable):hover {
  position: relative;
  z-index: 1;
}
.root :global(.avatarsCount) {
  color: var(--uui-text-primary);
  margin-left: 3px;
  line-height: 1;
  font-family: var(--uui-avatar_stack-font);
  font-weight: var(--uui-avatar_stack-font-weight);
  font-size: 14px;
}