@import "../../../../styles/common";
@import "../../../../styles/colors";
@import "../../../../styles/variables";

$header-height: 60px;
$logo-size: 36px;
$logo-url: "../../../../assets/icons/logo.svg";

.header {
  height: $header-height;
  width: 100%;
  max-width: $max-body-width;
  margin: 0 auto;
  display: flex;
  align-items: center;

  @media (max-width: $break-large) {
    max-width: $max-body-width-medium;
  }

  @media (max-width: $break-large) {
    padding: 0 10px;
  }

  .logo {
    display: flex;
    align-items: center;

    &__picture {
      display: flex;
      align-items: center;
      justify-content: center;
      height: $header-height;
      width: 26px;
      margin-right: 15px;
    }

    &__picture-background {
      height: $logo-size;
      width: $logo-size;
      background-image: url($logo-url);
      background-repeat: no-repeat;
      background-size: $logo-size;
      background-position: center;
    }
  }

  .title {
    @include semi-bold;
    color: $color-white;
    font-size: 20px;
    letter-spacing: 0.12px;
  }
}
