.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: 2px;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-calendar-selected_range-hover: var(--uui-primary-60);
  --uui-calendar-selected_range: var(--uui-primary-50);
  --uui-calendar-weekday-caption: var(--uui-text-tertiary);
  --uui-calendar-holiday-caption: var(--uui-text-tertiary);
  --uui-calendar-day-text: var(--uui-control-text);
  --uui-calendar-day-text-disabled: var(--uui-text-disabled);
  --uui-calendar-selected_day-text: var(--uui-neutral-0);
  --uui-calendar-selected_day-hover: var(--uui-control-bg);
  --uui-calendar-font: var(--uui-font);
  --uui-calendar-font-weight: 400;
  --uui-calendar-weekday-font: var(--uui-font);
  --uui-calendar-weekday-font-weight: 400;
  --uui-calendar-selected_day-font: var(--uui-font);
  --uui-calendar-selected_day-font-weight: 600;
}
.root :global(.uui-calendar-content) {
  font-family: var(--uui-calendar-font);
  font-weight: var(--uui-calendar-font-weight, normal);
}
.root :global(.uui-calendar-weekday) {
  font-family: var(--uui-calendar-weekday-font);
  font-weight: var(--uui-calendar-weekday-font-weight, normal);
  color: var(--uui-calendar-weekday-caption);
  width: 34px;
  font-size: 12px;
  line-height: 24px;
  text-align: center;
}
.root :global(.uui-calendar-day-cell) {
  color: var(--uui-calendar-day-text);
  text-align: center;
  padding: 0;
  height: 36px;
  width: 36px;
  display: flex;
  position: relative;
}
.root :global(.uui-calendar-day-cell):focus {
  outline: none;
}
.root :global(.uui-calendar-day-wrapper) {
  height: 30px;
  width: 30px;
  font-size: 14px;
  line-height: 30px;
  margin: auto;
  cursor: default;
}
.root :global(.uui-calendar-day) {
  border-radius: 50%;
  height: 36px;
  width: 36px;
  position: absolute;
  left: 0;
  top: 0;
  line-height: 34px;
  border: 1px solid transparent;
  box-sizing: border-box;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)), .root :global(.uui-calendar-clickable-day):focus:not(:global(.uui-calendar-filtered-day)) {
  outline: none;
  cursor: pointer;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)) :global(.uui-calendar-day), .root :global(.uui-calendar-clickable-day):focus:not(:global(.uui-calendar-filtered-day)) :global(.uui-calendar-day) {
  border: 1px solid var(--uui-calendar-selected_range);
  background-color: var(--uui-calendar-selected_day-hover);
  z-index: 1;
}
.root :global(.uui-calendar-clickable-day):hover:not(:global(.uui-calendar-filtered-day)):global(.uui-calendar-selected-day) :global(.uui-calendar-day), .root :global(.uui-calendar-clickable-day):focus:not(:global(.uui-calendar-filtered-day)):global(.uui-calendar-selected-day) :global(.uui-calendar-day) {
  border: 1px solid var(--uui-calendar-selected_range-hover);
  background-color: var(--uui-calendar-selected_range-hover);
}
.root :global(.uui-calendar-current-day):global(.uui-calendar-day-wrapper) {
  color: var(--uui-calendar-selected_range);
  font-family: var(--uui-calendar-selected_day-font);
  font-weight: var(--uui-calendar-selected_day-font-weight, normal);
}
.root :global(.uui-calendar-current-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day)::after {
  position: absolute;
  content: "";
  height: 4px;
  width: 4px;
  border-radius: 50%;
  background-color: var(--uui-calendar-selected_range);
  bottom: 3px;
  left: calc(50% - 2px);
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day) {
  background-color: var(--uui-calendar-selected_range);
  color: var(--uui-calendar-selected_day-text);
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-day-wrapper) :global(.uui-calendar-day)::after {
  background-color: var(--uui-calendar-selected_day);
}
.root :global(.uui-calendar-selected-day):global(.uui-calendar-clickable-day):hover {
  cursor: pointer;
}
.root :global(.uui-calendar-day-holiday) {
  color: var(--uui-calendar-holiday-caption);
}
.root :global(.uui-calendar-filtered-day):global(.uui-calendar-day-wrapper) {
  color: var(--uui-calendar-day-text-disabled);
}
.root :global(.uui-calendar-filtered-day):global(.uui-calendar-day-wrapper):hover {
  cursor: default;
  background-color: transparent;
}