@import "../../../styles/colors";
@import "../../../styles/variables";

$phone-number-border-color: #ced0db;
$phone-number-value-text-color: #ced0db;

$phone-number-us-url: "../../../assets/png/flag/us.png";
$phone-number-ru-url: "../../../assets/png/flag/by.png";

.phone-number {
  align-items: center;
  border: 1px solid $phone-number-border-color;
  border-radius: 3px;
  display: inline-flex;
  height: 60px;
  padding: 0 10px;
  width: 50%;
  max-width: 224px;

  @media (max-width: $break-medium) {
    margin-right: 10px;
  }

  @media (max-width: $break-small) {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  &__flag {
    background-color: $color-white;
    border-radius: 10px;
    height: 20px;
    width: 20px;

    &--us {
      background-image: url($phone-number-us-url);
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }

    &--ru {
      background-image: url($phone-number-ru-url);
      -webkit-background-size: cover;
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
    }
  }
  &__content {
    display: flex;
    flex-direction: column;
    &-text {
      color: $phone-number-value-text-color;
      font-size: 12px;
      margin-left: 10px;
    }
    &-value {
      color: $phone-number-value-text-color;
      font-size: 14px;
      letter-spacing: 0.1px;
      margin-left: 10px;
    }
  }
}
