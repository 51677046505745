@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Regular.ttf.woff2")
      format("woff2"),
    url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Regular.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Semibold.ttf.woff2")
      format("woff2"),
    url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-SemiBold.otf.woff")
      format("woff");
}

@font-face {
  font-family: "Source Sans Pro";
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff2/ttf/SourceSansPro-Bold.ttf.woff2")
      format("woff2"),
    url("https://static.cdn.epam.com/uploads/690afa39a93c88c4dd13758fe1d869d5/EPM-UUI/fonts/source-sans-pro/woff/otf/SourceSansPro-Bold.otf.woff")
      format("woff");
}
