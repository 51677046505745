@import "../../../../styles/colors";
@import "../../../../styles/variables";

$contact-us-underline-color: #30b6dd;
$contact-us-message-text-color: #c0c3ce;

.contact-us {
  padding: 16px 40px;

  @media (max-width: $break-medium) {
    padding: 16px 18px;
  }

  .title {
    color: $color-white;
    display: inline-block;

    &__underline {
      background-color: $contact-us-underline-color;
      height: 4px;
      margin: 12px 0;
      width: 100%;
    }
  }

  &__message {
    color: $contact-us-message-text-color;
    font-size: 14px;
    white-space: pre-wrap;
    line-height: 24px;
  }

  &__numbers {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-top: 12px;
    @media (max-width: $break-medium) {
      justify-content: flex-start;
    }
    @media (max-width: $break-small) {
      flex-direction: column;
      align-items: flex-start;
    }
  }
}
