$color-white: #ffffff;
$color-black: #ffffff;

$color-overlay: rgba(25, 25, 25, 0.9);

$color-main-gradient-from: #30b6dd;
$color-main-gradient-to: #436ad6;

$color-footer-background: #2c2f3c;
$color-main-background: #f2f3f7;
$color-accent: #fdbe4c;
$color-warning-text: #f3800d;
$color-secondary: #9bc837;
$color-error: #cd5485;

$color-text-main: #474a59;
$color-text-secondary: #525462;
$color-text-link: #30b6dd;
