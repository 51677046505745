.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: 2px;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.cell {
  color: var(--uui-text-primary);
  align-items: center;
  padding: 0 6px;
  width: 0;
  background-clip: padding-box;
}
.cell.column-gap-12 {
  padding: 0 6px;
  column-gap: 3px;
}
.cell.column-gap-12 .caption-wrapper {
  column-gap: 3px;
}
.cell.column-gap-24 {
  padding: 0 12px;
  column-gap: 3px;
}
.cell.column-gap-24 .caption-wrapper {
  column-gap: 3px;
}
.cell.first-column-12 {
  padding-left: 12px;
}
.cell.last-column-12 {
  padding-right: 12px;
}
.cell.first-column-24 {
  padding-left: 24px;
}
.cell.last-column-24 {
  padding-right: 24px;
}
.cell:global(.-clickable) {
  cursor: pointer;
}
.cell:global(.-clickable):hover {
  background: var(--uui-dt-row-bg-hover);
  box-shadow: inset 1px 0 0 var(--uui-dt-border), inset -1px 0 0 var(--uui-dt-border);
}
.cell:global(.-clickable):hover:not(:has(:global(.-clickable):hover)) .sort-icon, .cell:global(.-clickable):hover:not(:has(:global(.-clickable):hover)) .dropdown-icon, .cell:global(.-clickable):hover:not(:has(:global(.-clickable):hover)) .infoIcon {
  fill: var(--uui-icon-hover);
}
.cell.resizable:hover {
  box-shadow: inset 1px 0 0 var(--uui-dt-border), inset -1px 0 0 var(--uui-dt-border);
}
.cell.size-24 {
  height: 24px;
}
.cell.size-24 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-24 ~ :global(.config-icon) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-30 {
  height: 30px;
}
.cell.size-30 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-30 ~ :global(.config-icon) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-36 {
  height: 36px;
}
.cell.size-36 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-36 ~ :global(.config-icon) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-42 {
  height: 42px;
}
.cell.size-42 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-42 ~ :global(.config-icon) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-48 {
  height: 48px;
}
.cell.size-48 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-48 ~ :global(.config-icon) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-60 {
  height: 60px;
}
.cell.size-60 :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.size-60 ~ :global(.config-icon) :global(.uui-icon) svg {
  height: 18px;
  width: inherit;
}
.cell.padding-left-24 {
  padding-left: 24px;
}
.cell.padding-right-24 {
  padding-right: 24px;
}
.cell .caption-wrapper {
  display: flex;
  overflow: hidden;
  flex: 1 1 0;
  min-width: 0;
}
.cell .caption-wrapper.align-right {
  flex-direction: row-reverse;
}
.cell .caption-wrapper.align-center {
  justify-content: center;
}
.cell .caption {
  font-family: var(--uui-dt-header-cell-font);
  font-weight: var(--uui-dt-header-cell-font-weight);
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin: 0;
}
.cell .checkbox {
  padding: 0 9px 0 0;
}
.cell .icon {
  display: flex;
  flex-shrink: 0;
  cursor: pointer;
}
.cell .fold-all-icon {
  display: flex;
  padding: 0 6px 0 0;
  align-self: stretch;
}
.cell .fold-all-icon:hover {
  fill: var(--uui-icon-hover);
}
.cell .cell-tooltip {
  top: 0;
}
.cell .upper-case {
  text-transform: uppercase;
  font-size: 12px;
}
.cell .font-size-14 {
  font-size: 14px;
}
.cell .resize-mark {
  position: absolute;
  right: 0;
  height: 100%;
  cursor: col-resize;
  user-select: none;
}
.cell .resize-mark:hover {
  background-color: var(--uui-dt-cell-bg-resize);
}
.cell .resize-mark-6 {
  width: 6px;
}
.cell .resize-mark-12 {
  width: 12px;
}
.cell.pinned-right .resize-mark {
  left: 0;
}
.cell.draggable {
  cursor: pointer;
}
.cell.ghost {
  box-shadow: 0 6px 18px 0 rgba(29, 30, 38, 0.05), 0 3px 12px 0 rgba(29, 30, 38, 0.05);
  background-color: var(--uui-dt-row-bg-hover);
}
.cell.is-dragged-out {
  background-color: var(--uui-dt-row-bg-hover);
  opacity: 0.5;
}
.cell.dnd-marker-left {
  box-shadow: inset 2px 0 0 0 var(--uui-dt-header-drop_marker);
}
.cell.dnd-marker-left:global(.-clickable), .cell.dnd-marker-left:local(.resizable) {
  box-shadow: inset 2px 0 0 0 var(--uui-dt-header-drop_marker);
}
.cell.dnd-marker-right {
  box-shadow: inset -2px 0 0 0 var(--uui-dt-header-drop_marker);
}
.cell.dnd-marker-right:global(.-clickable), .cell.dnd-marker-right:local(.resizable) {
  box-shadow: inset -2px 0 0 0 var(--uui-dt-header-drop_marker);
}

.cell-tooltip-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  row-gap: 3px;
}
.cell-tooltip-wrapper .cell-tooltip-text {
  color: var(--uui-tooltip-text);
  padding: 0;
}