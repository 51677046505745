.root {
  --uui-pagination-font: var(--uui-font);
  --uui-pagination-font-weight: 400;
  display: flex;
  flex-direction: row;
}
.root .spacer {
  color: var(--uui-text-primary);
}
.root .mode-ghost {
  color: var(--uui-text-primary);
}
.root .mode-ghost:hover {
  color: var(--uui-primary-50);
}
.root .size-24:global(.uui-button-box) {
  min-width: 24px;
}
.root .size-24 :global(.uui-caption) {
  font-family: var(--uui-pagination-font);
  font-weight: var(--uui-pagination-font-weight, normal);
  font-size: 14px;
  padding-left: 2px;
  padding-right: 2px;
}
.root .size-24:first-of-type {
  margin-right: 6px;
}
.root .size-24:last-of-type {
  margin-left: 6px;
}
.root .size-24.spacer {
  min-width: 28px;
}
.root .size-30:global(.uui-button-box) {
  min-width: 30px;
}
.root .size-30 :global(.uui-caption) {
  font-family: var(--uui-pagination-font);
  font-weight: var(--uui-pagination-font-weight, normal);
  font-size: 14px;
  padding-left: 5px;
  padding-right: 5px;
}
.root .size-30:first-of-type {
  margin-right: 6px;
}
.root .size-30:last-of-type {
  margin-left: 6px;
}
.root .size-30.spacer {
  min-width: 34px;
}
.root .navigation-size-24:global(.uui-button-box) {
  min-width: 24px;
}
.root .navigation-size-24 :global(.uui-caption) {
  font-family: var(--uui-pagination-font);
  font-weight: var(--uui-pagination-font-weight, normal);
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
}
.root .navigation-size-24:first-of-type {
  margin-right: 6px;
}
.root .navigation-size-24:last-of-type {
  margin-left: 6px;
}
.root .navigation-size-30:global(.uui-button-box) {
  min-width: 30px;
}
.root .navigation-size-30 :global(.uui-caption) {
  font-family: var(--uui-pagination-font);
  font-weight: var(--uui-pagination-font-weight, normal);
  font-size: 14px;
  padding-left: 0;
  padding-right: 0;
}
.root .navigation-size-30:first-of-type {
  margin-right: 6px;
}
.root .navigation-size-30:last-of-type {
  margin-left: 6px;
}