@import "../../../styles/variables";
@import "../../../styles/colors";

$app-min-width-medium: 1240px;
$app-min-height: 240px;

.app {
  background: $main-gradient;
  background-size: 100% 100vh;
  color: $color-text-main;

  &__view {
    min-height: calc(100vh - 262px);
    display: flex;
    flex-direction: column;
  }

  @media (max-width: $break-large) {
    // min-width: $app-min-width-medium;
  }

  &__loading {
    min-height: calc(100vh - 262px);
    background-color: $color-white;
    position: relative;
  }
}
