.root {
  --uui-burger_menu-bg: var(--uui-neutral-90);
  --uui-burger_menu-bg-hover: var(--uui-neutral-90);
  --uui-burger_menu-overlay: var(--uui-overlay);
  --uui-burger_menu-icon: var(--uui-neutral-5);
  --uui-burger_menu-item: var(--uui-text-primary);
  position: relative;
  display: flex;
}
.root:global(.uui-burger-menu-open) {
  z-index: 1001;
}
.root :global(.uui-burger-button) {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  cursor: pointer;
  fill: var(--uui-burger_menu-icon);
  background: transparent;
  padding: 0;
  border: 0 none;
}
.root :global(.uui-burger-button):hover {
  background-color: var(--uui-burger_menu-bg-hover);
}
.root :global(.uui-burger-button):active {
  background-color: var(--uui-burger_menu-bg-hover);
}
.root :global(.uui-burger-button):focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: -2px;
  border-radius: 0;
}
.root :global(.uui-burger-button) :global(.uui-icon) svg {
  width: 24px;
  height: 24px;
}
.root :global(.uui-burger-logo-container) {
  position: absolute;
  left: 60px;
  top: 0;
}
.root :global(.uui-burger-logo) {
  display: block;
  height: 60px;
}

.burger-content:global(.uui-burger-overlay):global(.uui-burger-overlay-visible) {
  position: fixed;
  z-index: 1000;
  top: 60px;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--uui-burger_menu-overlay);
}
.burger-content :global(.uui-burger-items) {
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  width: 300px;
  background: var(--uui-burger_menu-item);
  transform: translateX(-100px);
}
.burger-content :global(.uui-burger-items):global(.uui-burger-items-visible) {
  transform: translateX(0);
}