@import "../../../../styles/common";
@import "../../../../styles/colors";
@import "../../../../styles/variables";

$footer-height: 202px;
$logo-size: 36px;
$logo-url: "../../../../assets/icons/logo.svg";

.footer {
  align-items: center;
  max-width: $max-body-width;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  // height: $footer-height;
  width: 100%;

  @media (max-width: $break-large) {
    max-width: $max-body-width-medium;
  }

  @media (max-width: $break-medium) {
    flex-direction: column-reverse;
  }

  &__info {
    @media (max-width: $break-medium) {
      width: 100%;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      margin: 20px 0 30px;
    }
  }

  &__container {
    background-color: $color-footer-background;
  }

  &__contacts {
    background: linear-gradient(225deg, #2c2f3c 0%, #494e5c 100%);
    height: auto;
    width: 100%;
    max-width: 534px;
    @media (max-width: $break-medium) {
      max-width: 100%;
    }
  }

  &__security-notice {
    padding-left: 60px;
    margin-top: -20px;
    margin-bottom: 10px;
    h5 {
      color: #fff;
      font-size: 14px;
      margin-bottom: 6px;
      @include semi-bold();
      margin-top: 12px;
    }
    p {
      color: #9fa1ae;
      font-size: 14px;
      max-width: 470px;
      padding-right: 20px;
    }
  }

  .logo {
    display: flex;
    align-items: center;

    &__picture {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 60px;
      width: 60px;
    }

    &__picture-background {
      height: $logo-size;
      width: $logo-size;
      background-image: url($logo-url);
      background-repeat: no-repeat;
      background-size: $logo-size;
    }
  }

  .title {
    @include semi-bold;
    color: $color-white;
    font-size: 20px;
    letter-spacing: 0.12px;
  }

  .copyright {
    color: #9fa1ae;
    font-size: 14px;
    line-height: 18px;
    margin-left: 60px;
  }
}
