.notifications {
  &__container {
    position: fixed;
    left: 10px;
    bottom: 60px;
  }
  &__children {
    margin-top: 10px;
  }
}
