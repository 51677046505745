@import "../../../../styles/colors";

$close-icon: "../../../../assets/icons/close.svg";

.notification {
  width: 360px;
  height: auto;
  background: #fff;
  border-radius: 3px;
  box-shadow: 0 4px 22px 0 rgba(44, 47, 60, 0.16);
  padding: 0;
  position: relative;
  overflow: hidden;
  &__inner {
    margin: 12px 18px 12px 20px;
    display: flex;
  }
  &__upper-colored {
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
    height: 6px;
  }
  &__content {
    margin-right: 20px;
    margin-left: 14px;
  }
  &__text {
    font-size: 14px;
    line-height: 24px;
  }

  &__actions {
    margin-top: 10px;

    > div {
      font-weight: 600 !important;
    }
  }

  // buttons below
  &__action {
    color: $color-text-link;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    text-transform: uppercase;
    border: 0;
    background: none;
    padding: 0;
    cursor: pointer;
  }

  // (!) icon
  &__icon {
    width: 18px;
    height: 18px;
    border-radius: 9px;
    color: $color-white;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }
  &__icon-container {
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-size: 100% auto;
  }

  // (X) button
  &__close {
    width: 15px;
    height: 15px;
    background: none;
    border: 0;
    background-repeat: no-repeat;
    background-size: 100% auto;
    background-image: url($close-icon);
    margin-left: auto;
    cursor: pointer;
    outline: none;
  }

  // notification types
  &--error {
    .notification__upper-colored,
    .notification__icon {
      background: $color-error;
    }
  }
  &--warning {
    .notification__upper-colored,
    .notification__icon {
      background: $color-accent;
    }
  }
}

.issue-tag {
  width: 18px;
  height: 18px;
  border-radius: 9px;
  color: $color-white;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;

  &.concern {
    background-color: $color-error;
  }

  &.question {
    background-color: $color-secondary;
  }
}
