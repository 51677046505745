$ru_flag: "../../../assets/png/flag/ru.png";
$us_flag: "../../../assets/png/flag/us.png";
$by_flag: "../../../assets/png/flag/by.png";

.language-picker {
  margin-left: auto;
  display: flex;
  align-items: center;
  &__toggler {
    color: #fff;
    display: flex;
    align-items: center;
  }

  &__flag {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background-size: cover;
    background-position: center;
    &--ru {
      background-image: url($ru_flag);
    }
    &--en {
      background-image: url($us_flag);
    }
    &--ru-RU {
      background-image: url($ru_flag);
    }
  }

  &__select {
    border: 0;
    .Dropdown-control {
      background: transparent;
      border: 0;
      color: #fff;
      padding-right: 22px;
      padding-left: 6px;
      font-size: 14px;
      cursor: pointer;
      &:hover {
        box-shadow: none;
      }
    }

    .Dropdown-arrow {
      border-color: #fff transparent transparent;
      margin-top: 3px;
      border-width: 3px 3px 0px;
    }

    .Dropdown-menu {
      border-radius: 6px;
      border: none;
      font-size: 14px;
      width: 220px;
      right: 0;
    }

    &.is-open {
      .Dropdown-arrow {
        border-color: transparent transparent #fff;
        border-width: 0px 3px 3px;
      }
    }
  }

  .uui-icon {
    fill: #fff;
  }
}
