.error-page {
  background-color: white;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 100px;
  height: calc(100vh - 100px - 205px - 60px);

  &__section {
    background-color: #fff;
    border-radius: 6px;
    box-shadow:
      0 1px 2px 0 rgba(44, 47, 60, 0.06),
      0 3px 10px 0 rgba(44, 47, 60, 0.09);
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0 36px;
    font-size: 18px;
    position: relative;
    top: -90px;
    height: 350px;

    &-title {
      background-color: #f2f3f7;
      display: flex;
      justify-content: center;
      width: calc(100% + 72px);
      margin: 0 -36px;
      font-size: 28px;
      font-weight: bold;
    }

    a {
      padding-left: 7px;
      color: #007bff;
      text-decoration: none;
    }

    a:hover {
      color: #0056b3;
    }
  }
}
