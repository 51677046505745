.container:global(.uui-monthselection-container) :global(.uui-monthselection-content) {
  display: flex;
  flex-direction: column;
  font-size: 14px;
}
.container:global(.uui-monthselection-container) :global(.uui-monthselection-month-container) {
  display: flex;
  flex-direction: column;
}
.container:global(.uui-monthselection-container) :global(.uui-monthselection-month-container) > * {
  display: flex;
}
.container:global(.uui-monthselection-container) :global(.uui-monthselection-month) {
  display: flex;
  justify-content: center;
  align-items: center;
}