.uui-typography {
  font-family: var(--uui-font);
  color: var(--uui-text-primary);
}
.uui-typography .hero-header, .uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin: 0;
}
.uui-typography .hero-header {
  font-family: var(--uui-font);
  font-weight: 500;
  margin: 30px 0;
  font-size: 66px;
  line-height: 72px;
}
.uui-typography .promo-header, .uui-typography h1, .uui-typography h2, .uui-typography h3 {
  font-family: var(--uui-font);
  font-weight: 700;
}
.uui-typography h4, .uui-typography h5, .uui-typography h6 {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography h1, .uui-typography h2, .uui-typography h3, .uui-typography h4, .uui-typography h5, .uui-typography h6 {
  margin-bottom: 0.5em;
  margin-top: 1.2em;
  line-height: 1.35em;
}
.uui-typography h1 {
  font-size: 42px;
}
.uui-typography h2 {
  font-size: 30px;
  letter-spacing: 0.1px;
}
.uui-typography h2 .promo-header {
  letter-spacing: 2px;
}
.uui-typography h3 {
  font-size: 24px;
}
.uui-typography h4 {
  font-size: 18px;
  letter-spacing: 0.1px;
}
.uui-typography h5 {
  font-size: 16px;
}
.uui-typography h6 {
  font-size: 14px;
}
.uui-typography b, .uui-typography strong {
  font-family: var(--uui-font);
  font-weight: 600;
}
.uui-typography i, .uui-typography em {
  font-family: var(--uui-font);
  font-weight: 400;
  font-style: italic;
}
.uui-typography a {
  display: inline-flex;
  align-items: center;
  color: var(--uui-link);
  text-decoration: underline;
}
.uui-typography a:focus-visible {
  outline: var(--uui-focus-outline-width) solid var(--uui-outline-focus);
  outline-offset: 2px;
  border-radius: 2px;
}
.uui-typography a:visited {
  color: var(--uui-link-visited);
}
.uui-typography a:visited:hover {
  color: var(--uui-link-visited-hover);
}
.uui-typography a:hover {
  color: var(--uui-link-hover);
}
.uui-typography code {
  padding: 0.125em 0.25em;
  color: var(--uui-text-primary);
  background-color: var(--uui-neutral-30);
  font-family: var(--uui-font-mono, monospace);
  font-weight: 400;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography ol, .uui-typography ul {
  padding: 0;
}
.uui-typography li {
  margin-left: 1.25em;
  line-height: 1.5;
}
.uui-typography pre {
  padding: 1.25em;
  font-family: monospace;
  white-space: pre-wrap;
  word-wrap: normal;
}
.uui-typography img {
  max-width: 100%;
}
.uui-typography figure {
  margin: 0;
}
.uui-typography figure figcaption {
  font-size: 0.9em;
  opacity: 0.9;
  text-align: center;
}
.uui-typography p {
  margin: 0.5em 0;
  line-height: 1.5;
}
.uui-typography .uui-critical {
  color: var(--uui-text-critical);
}
.uui-typography .uui-info {
  color: var(--uui-text-info);
}
.uui-typography .uui-success {
  color: var(--uui-text-success);
}
.uui-typography .uui-warning {
  color: var(--uui-text-warning);
}
.uui-typography .uui-highlight {
  background-color: var(--uui-warning-10);
}

.uui-typography-size-12 {
  font-size: 12px;
}

.uui-typography-size-14 {
  font-size: 14px;
}

.uui-typography-size-16 {
  font-size: 16px;
}

.root {
  --uui-text_input-bg: var(--uui-control-bg);
  --uui-text_input-bg-disabled: var(--uui-control-bg-disabled);
  --uui-text_input-bg-readonly: var(--uui-control-bg-disabled);
  --uui-text_input-border: var(--uui-control-border);
  --uui-text_input-border-hover: var(--uui-control-border-hover);
  --uui-text_input-border-active: var(--uui-control-border-focus);
  --uui-text_input-border-error: var(--uui-error-50);
  --uui-text_input-border-disabled: var(--uui-control-border-disabled);
  --uui-text_input-border-readonly: var(--uui-control-border-disabled);
  --uui-text_input-placeholder: var(--uui-control-placeholder);
  --uui-text_input-placeholder-disabled: var(--uui-control-placeholder-disabled);
  --uui-text_input-placeholder-readonly: var(--uui-control-placeholder-disabled);
  --uui-text_input-text: var(--uui-control-text);
  --uui-text_input-text-disabled: var(--uui-control-text-disabled);
  --uui-text_input-icon: var(--uui-control-icon);
  --uui-text_input-icon-disabled: var(--uui-control-icon-disabled);
  --uui-text_input-icon-clickable: var(--uui-icon);
  --uui-text_input-icon-clickable-hover: var(--uui-icon-hover);
  --uui-text_input-font: var(--uui-font);
  --uui-text_input-font-weight: 400;
  --uui-text_input-border-radius: var(--uui-border-radius);
  --uui-text_input-min-height: var(--uui-size);
  --uui-text_input-min-width: var(--uui-size);
  --uui-text_input-border-width: var(--uui-border-width);
  --uui-text_input-horizontal-padding: var(--uui-horizontal-padding);
  --uui-text_input-horizontal-gap: var(--uui-horizontal-gap);
  --uui-text_input-icon-height: var(--uui-icon-size);
  --uui-text_input-line-height: var(--uui-line-height);
  --uui-text_input-font-size: var(--uui-font-size);
  fill: var(--uui-text_input-icon);
  border-style: solid;
  box-sizing: border-box;
  min-width: var(--uui-text_input-min-width);
  padding-left: calc(var(--uui-text_input-horizontal-padding) - var(--uui-text_input-border-width));
  padding-right: calc(var(--uui-text_input-horizontal-padding) - var(--uui-text_input-border-width));
  border-radius: var(--uui-text_input-border-radius);
}
.root:global(.uui-input-box) {
  height: var(--uui-text_input-min-height);
  border-width: var(--uui-text_input-border-width);
}
.root :global(.uui-input) {
  color: var(--uui-text_input-text);
  font-family: var(--uui-text_input-font);
  font-weight: var(--uui-text_input-font-weight);
  line-height: var(--uui-text_input-line-height);
  font-size: var(--uui-text_input-font-size);
  padding: calc((var(--uui-text_input-min-height) - var(--uui-text_input-line-height) - var(--uui-text_input-border-width) * 2) / 2) var(--uui-text_input-horizontal-gap);
}
.root :global(.uui-input)::placeholder {
  color: var(--uui-text_input-placeholder);
}
.root :global(.uui-input):placeholder-shown {
  text-overflow: ellipsis;
}
.root :global(.uui-input)::-moz-placeholder {
  opacity: 1;
}
.root :global(.uui-icon) svg {
  height: var(--uui-text_input-icon-height);
  width: inherit;
}
.root.mode-form {
  background-color: var(--uui-text_input-bg);
  border-color: var(--uui-text_input-border);
}
.root.mode-form :global(.-clickable) {
  fill: var(--uui-text_input-icon-clickable);
}
.root.mode-form :global(.-clickable):hover {
  fill: var(--uui-text_input-icon-clickable-hover);
}
.root.mode-form:hover {
  border-color: var(--uui-text_input-border-hover);
}
.root.mode-form:global(.uui-focus) {
  border-color: var(--uui-text_input-border-active);
}
.root.mode-inline {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-inline :global(.-clickable) {
  fill: var(--uui-text_input-icon-clickable);
}
.root.mode-inline :global(.-clickable):hover {
  fill: var(--uui-text_input-icon-clickable-hover);
}
.root.mode-inline:hover {
  border-color: var(--uui-text_input-border-hover);
}
.root.mode-inline:global(.uui-focus) {
  border-color: var(--uui-text_input-border-active);
}
.root.mode-cell {
  background-color: transparent;
  border-color: transparent;
}
.root.mode-cell :global(.-clickable) {
  fill: transparent;
}
.root.mode-cell:hover :global(.-clickable) {
  fill: var(--uui-text_input-icon-clickable);
}
.root.mode-cell:hover :global(.-clickable):hover {
  fill: var(--uui-text_input-icon-clickable-hover);
}
.root:global(.uui-invalid).mode-form, .root:global(.uui-invalid).mode-inline {
  background-color: var(--uui-text_input-bg);
  border-color: var(--uui-text_input-border-error);
}
.root:global(.uui-readonly) {
  color: var(--uui-text_input-text);
  fill: var(--uui-text_input-icon);
  pointer-events: none;
}
.root:global(.uui-readonly) :global(.uui-input)::placeholder {
  -webkit-text-fill-color: var(--uui-text_input-placeholder-readonly);
  color: var(--uui-text_input-placeholder-readonly);
}
.root:global(.uui-readonly).mode-form {
  background-color: var(--uui-text_input-bg-readonly);
  border-color: var(--uui-text_input-border-readonly);
}
.root:global(.uui-disabled) {
  color: var(--uui-text_input-text-disabled);
  fill: var(--uui-text_input-icon-disabled);
  pointer-events: none;
}
.root:global(.uui-disabled) :global(.uui-input) {
  -webkit-text-fill-color: var(--uui-text_input-text-disabled);
  color: var(--uui-text_input-text-disabled);
}
.root:global(.uui-disabled) :global(.uui-input)::placeholder {
  -webkit-text-fill-color: var(--uui-text_input-placeholder-disabled);
  color: var(--uui-text_input-placeholder-disabled);
}
.root:global(.uui-disabled).mode-form {
  background-color: var(--uui-text_input-bg-disabled);
  border-color: var(--uui-text_input-border-disabled);
}