@import "./colors";

$main-gradient: linear-gradient(
  242.61deg,
  $color-main-gradient-from 0%,
  $color-main-gradient-to 100%
);
$max-body-width: 1480px;
$max-body-width-medium: 1200px;

$break-large: 1480px;
$break-medium-large: 1200px;
$break-medium: 980px;
$break-medium-small: 736px;
$break-small: 480px;
