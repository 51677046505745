@mixin bold {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 700;
}

@mixin semi-bold {
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
}

@mixin opaque-background {
  background-color: rgba(255, 255, 255, 0.9);
}
