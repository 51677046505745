@import "./styles/common";
@import "./styles/colors";
@import "./styles/variables";
@import "./styles/fonts.scss";

body {
  margin: 0;
  font-family: "Source Sans Pro", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: $color-footer-background;
}

p {
  margin: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

* {
  box-sizing: border-box;
}

.button-content {
  padding: 0 6px;
  font-family: "Source Sans Pro", arial;
  &--big {
    padding: 0 11px;
  }
  &--no-padding {
    padding: 0;
  }
}

input,
textarea {
  font-family: "Source Sans Pro", arial !important;
}
