.container:global(.uui-datepickerheader-container) :global(.uui-datepickerheader-header) {
  display: flex;
  justify-content: space-between;
}
.container:global(.uui-datepickerheader-container) :global(.uui-datepickerheader-nav-title) {
  display: flex;
  align-items: center;
}

*[dir=rtl] .container :global(.uui-datepickerheader-nav-icon-left) svg, .container[dir=rtl] :global(.uui-datepickerheader-nav-icon-left) svg {
  transform: rotate(180deg);
}

*[dir=rtl] .container :global(.uui-datepickerheader-nav-icon-right) svg, .container[dir=rtl] :global(.uui-datepickerheader-nav-icon-right) svg {
  transform: rotate(180deg);
}