.cell {
  background-clip: padding-box;
  align-items: flex-start;
  background-color: var(--uui-dt-cell-bg);
}
.cell:first-child {
  border-left-color: transparent;
}
.cell:last-child {
  border-right-color: transparent;
}

.content-wrapper {
  display: flex;
  min-width: 0;
  flex: 1;
}

.editor-wrapper {
  flex: 1 1 0;
  min-width: 0;
  position: relative;
  align-self: stretch;
  background-color: var(--uui-dt-cell-editor-bg);
}